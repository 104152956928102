<template>
  <div class="newsdetail" >
    <!-- v-scroll="MonitorScroll"  -->
    <banner :bannerIndex="2" />
    <div class="public_title animate__animated animate__fadeInDown" v-if="fieldShow">业务领域</div>
    <div class="content_w field_box animate__animated animate__fadeInLeft" v-if="fieldShow" >
      <div class="field_one animation_img" v-for="(item,index) in fieldsList" :key="index"  v-show="navctiveIndex == index">
        <div class="field_desc field_text" >{{item.title2}}</div>
        <img :src="item.pic" class="enlarge"/>
      </div>
      <ul class="field_two" v-show="inwidth>768">
        <li v-for="(item,index) in fieldsList" :key="index" :class="navctiveIndex === index ? 'field_bg':''" @mouseenter="fieldFn(index)" >
          <div class="field_num">0{{index+1}}</div>
          <div class="field_cont">
            <img :src="item.pic2" class="fd_one"/>
            <img :src="item.pic3" class="fd_two"/>
            <span>{{item.title}}</span>
          </div>
        </li>
      </ul>
      <ul class="field_two" v-show="inwidth<=768">
        <li v-for="(item,index) in fieldsList" :key="index" :class="navctiveIndex === index ? 'field_bg':''" @click="fieldFn(index)" >
          <div class="field_num">0{{index+1}}</div>
          <div class="field_cont">
            <img :src="item.pic2" class="fd_one"/>
            <img :src="item.pic3" class="fd_two"/>
            <span>{{item.title}}</span>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="!fieldShow" class="height2r"></div>
  </div>
</template>

<script>
import banner from "../components/home/banner.vue";
export default {
  name: "field",
   components: {
    banner
  },
  data() {
    return {
      fieldsList:[],
      fieldShow:false,
      navctiveIndex:this.$route.query.navctiveIndex || 1,
      inwidth: window.innerWidth,
      newsid: this.$route.query.newsid,
      
    };
  },
  watch: {
    $route(to, from) {
      console.log('to',to)
      this.navctiveIndex = to.query.navctiveIndex;
    }
  },
 mounted() {
    window.addEventListener("scroll", this.onScroll,true);
    if(this.inwidth>768){
      setTimeout((res) => {
        this.fieldShow = true;
      }, 2500);
    }else{
      this.fieldShow = true;
    }
    this.fields_list()
  },
  methods: {
    onScroll(){
      console.log('aaaa')
				let inHeight = window.innerHeight
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			if(scrollTop>0){
        this.fieldShow = true;
			}
		},
    fields_list() {
      this.$api.fields_list({}).then((res) => {
        if (res.status == 1) {
          this.fieldsList = res.data
        } else {
          layer.msg(res.msg);
        }
      });
    },
    fieldFn(index){
      this.navctiveIndex = index;
    }
  },
};
</script>

<style scoped>
.field_one{
  flex: 1;
  position: relative;
}
.field_one img{
  width: 100%;
}
.field_box{
  display: flex;
  justify-content: space-between;
  height: 6.1rem;
  margin-bottom: 2.1rem;
}
.field_two{
 width: 35%;
 z-index: 100;
}
.field_two li{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .18rem;
  background: #a21003;
  height: 25%;
  cursor: pointer;
}

.field_two li:nth-child(odd){
  background: #ab271c;
  
}
.field_num{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .36rem;
  color: #fff;
  font-weight: bold;
  width: 35%;
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, .2);
}
.field_cont{
  font-size: .18rem;
  color: #fff;
  flex: 1;
}
.field_cont img{
  display: block;
  width: .4rem;
  margin: 0 auto;
}
.field_cont span{
  display: block;
  text-align: center;
}
.field_cont  .fd_one{
  display: none;
}
.field_cont  .fd_two{
  display: block;
}
.field_one img{
  width:100%;
  height: 100%;
  object-fit: cover;
}
.field_desc{
  position: absolute;
  left: .2rem;
  top: .2rem;
  right: .2rem;
  bottom: .2rem;
  background: rgba(0, 0, 0, .4);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .24rem;
  color: #fff;
  z-index: 99;
}
.field_text{
  padding: .5rem;
}
.field_two li:hover ,.field_two .field_bg{
  background: #fff!important;
  margin-left: -.5rem;
  padding-left: .5rem;
}
.field_two li:hover .fd_one,.field_two .field_bg .fd_one{
  display: block;
} 

.field_two li:hover .fd_two,.field_two .field_bg .fd_two{
  display: none;
} 

.field_two li:hover .field_cont,.field_two .field_bg .field_cont{
  color: #4c4c4c;
}

.field_two li:hover  .field_num ,.field_two .field_bg .field_num{
 color: #4c4c4c;
}

@media (max-width: 1400px) {
  .field_box{
  margin-bottom: 1.5rem;
  }
}


@media (max-width: 1200px) {
}
@media (max-width: 768px) {
  .modular3_wz{
    width: 90%;
  }
}
@media (max-width: 480px) {
  .field_two{
    width: 40%;
  }
  .field_cont{
    font-size: 12px;
  }
  .field_two li:hover, .field_two .field_bg{
    margin-left: -.3rem;
    padding-left: .3rem;
  }
  .field_text{
    font-size: 14px;
    line-height: 24px;
  }
  
}
</style>
